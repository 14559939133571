body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#reds {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 100vh;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.wrapper .items {
  flex: 1;
}

.wrapper .items .item {
  max-height: 40px;
}
